import type { Images } from '@neos/app'
import calendarPath from '../../modules/Tracking/images/Calendar.svg'
import lockPath from '../../modules/Tracking/images/Lock.svg'
import lockSlotPath from '../../modules/Tracking/images/LockSlot.svg'

const images: Images = {
  calendar: { path: calendarPath, colored: true },
  lock: { path: lockPath, colored: true },
  lockSlot: { path: lockSlotPath, colored: true },
}

export default images