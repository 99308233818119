import { View } from '@neos/app'

export const views: View[] = [
  {
    name: 'IndispoView',
    openMode: 'newFrame',
    component: () => import('./Unbound/IndispoView/view/IndispoView.vue')
  },
  {
    name: 'MroadCalendarTrancheUI',
    openMode: 'newFrame',
    component: () => import('./MroadCalendrierTrancheView/MroadCalendarTrancheUI/view/MroadCalendarTrancheUI.vue')
  },
  {
    name: 'MroadCalendarUI',
    openMode: 'newFrame',
    component: () => import('./MroadCalendrierView/MroadCalendarUI/view/MroadCalendarUI.vue')
  },
  {
    name: 'MroadDetailUI',
    openMode: 'newFrame',
    component: () => import('./MroadDetailView/MroadDetailUI/view/MroadDetailUI.vue')
  },
  {
    name: 'MroadEventUI',
    openMode: 'newFrame',
    component: () => import('./MroadEventView/MroadEventUI/view/MroadEventUI.vue')
  },
  {
    name: 'MroadOrderUI',
    openMode: 'newFrame',
    component: () => import('./MroadOrderView/MroadOrderUI/view/MroadOrderUI.vue')
  },
]