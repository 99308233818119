import { createApp } from '@neos/app'
import { views } from './views'
import { menuItems } from './menuItems'
import images from './images'
import '@neos/app/dist/style.css'

const themes = import.meta.glob<boolean, string, string | undefined>('./Themes/*.css', {
  query: '?inline',
  import: 'default',
})

createApp({
  production: import.meta.env.PROD,
  name: `TMSVirTracking`,
  title: `TrackingTMSVIR`,
  version: `0.0.1`,
  company: `Akanea`,
  languages: [`en`, `fr`],
  importResources: (lang) => import(`./Resources/${lang}.ts`),
  themes: [`Neos`, `NeosV1`, `VIR`],
  importTheme: (theme) => themes[`./Themes/${theme}.css`]?.(),
  hotModuleReload: import.meta.hot,
  views,
  menuItems,
  images,
  hasUICustomizationModule: false,
  mainUIViewName: `MroadOrderUI`,
  mainFramesContainerId: `HomePageFrameContainer`,
})